.carrusel-section {
  position: relative;
  padding: 8.8rem 0rem;
}
.carrusel-section .carrusel-section__title {
  margin-bottom: 3.8rem;
}
.carrusel-section .item {
  border-radius: 2.1rem;
  padding: 2.8rem;
  text-align: center;
  min-height: 485px;
}
.carrusel-section .item hr {
  color: var(--white-color);
  border-color: var(--white-color);
}
.carrusel-section .item .item-content {
  margin-top: 2.8rem;
  color: var(--white-color);
}
.carrusel-section .item a {
  text-decoration: none;
}
.carrusel-section .owl-carousel .owl-nav button.owl-next, .carrusel-section .owl-carousel .owl-nav button.owl-prev {
  background-color: var(--gray-color);
  height: 5.5rem;
  width: 5.5rem;
  border-radius: 50%;
}
.carrusel-section .owl-carousel .owl-nav button.owl-next span, .carrusel-section .owl-carousel .owl-nav button.owl-prev span {
  font-size: var(--fz-h3);
  font-weight: bold;
}
.carrusel-section .item > h4 {
  color: white;
}

.margin-top-135 {
  margin-top: 0px;
}

@media (min-width: 768px) {
  .carrusel-section .owl-carousel .owl-nav button.owl-next {
    position: absolute;
    right: -70px;
    top: 40%;
  }
  .carrusel-section .owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    left: -70px;
    top: 40%;
  }
}
@media (min-width: 1200px) {
  .margin-top-135 {
    margin-top: -135px !important;
  }
}
.home-pt {
  padding: 0rem 0rem 8.8rem 0rem !important;
}