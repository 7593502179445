.carrusel-section{
    position: relative;
    padding: 8.8rem 0rem;
    .carrusel-section__title{
        margin-bottom: 3.8rem;
    }
    .item {        
        border-radius: 2.1rem;
        padding: 2.8rem;
        text-align: center;
        min-height: 485px;
        hr{
            color: var(--white-color);
            border-color: var(--white-color);
        }
        .item-content{
            margin-top: 2.8rem;
            color: var(--white-color);
        }
        a{
            text-decoration: none;
        }
    }   
    .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev{
            background-color: var(--gray-color);
            height: 5.5rem;
            width: 5.5rem;
            border-radius: 50%;
            span{
                font-size: var(--fz-h3);
                font-weight: bold;
            }
        }

    .item>h4 {
        color: white;
    }
}
.margin-top-135{
    margin-top: 0px;
}
@media (min-width: 768px) {
    .carrusel-section{
        .owl-carousel .owl-nav button.owl-next{
            position: absolute;
            right: -70px;
            top: 40%;
        }
        .owl-carousel .owl-nav button.owl-prev{
            position: absolute;
            left: -70px;
            top: 40%;
        }    
    }
}
// Extra large devices
@media (min-width: 1200px) {
        .margin-top-135{
            margin-top: -135px!important;
        }
}

.home-pt{
    padding: 0rem 0rem 8.8rem 0rem!important;
}